<template>
    <div class="card card-danger">
        <div class="card-header">
            <h3 class="card-title">{{ $t('general.acceso') }}</h3>
            <div class="card-tools" v-if="permisomodif">
                <button  type="button" @click="guardar()" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <label >{{ $t('general.usuario') }}</label>
                        <input :disabled="!permisomodif" type="text" class="form-control nombre_completo dato" id="nombre_completo3" v-model="login" autocomplete="off">
                    </div>
                    
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input :disabled="!permisomodif" type="password" class="form-control nombre_completo dato" v-model="clave" id="nombre_completo123" autocomplete="new-password">
                    </div>   
                </div>
                <div v-if="operarioaux && operarioaux.idusuario" class="col-md-7">
                    <div class="form-group">
                        <label >{{ $t('general.correo') }}</label>
                        <input :disabled="!permisomodif" type="email" class="form-control nombre_completo dato" id="email_completo3" v-model="email">
                    </div>
                    
                </div>
                <div v-if="operarioaux && operarioaux.idusuario" class="col-md-5">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input :disabled="!permisomodif" type="password" class="form-control nombre_completo dato" v-model="claveemail" id="email_completo123" >
                    </div>   
                </div>
            </div>
            <!-- Se omite el acceso de email/clave ya que no se usa ni están contemplado en el acceso al webmail -->
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['operario', 'id', 'permisomodif'],
    data() {
        return {
            login: '',
            clave: '',
            claveemail: '',
            email: '',
        }
    },
    methods: {
        cargardatos() {
            console.log('userdat', this.operario);
            this.$store.dispatch('cambiarCargando', true);
            this.login = this.$props.operario.nombre_usuario;
            this.clave = this.$props.operario.contraseña;
            this.email = this.$props.operario.usuario_mail_usuario;
            this.claveemail = this.$props.operario.clave_mail_usuario;
            this.operarioaux = this.$props.operario;
            this.$store.dispatch('cambiarCargando', false);
            console.log('cargardatosoper', this.operario);
        },
        async guardar() {
            const api = new PwgsApi;
            this.$store.dispatch('cambiarGuardando', true);
                if(!this.operarioaux.idusuario){
                    try{
                        var resp = await api.post('usuarios', {operarios_idoperario:this.operarioaux.idoperario, nombre: this.login, contraseña: this.clave});
                        this.operarioaux.idusuario = resp.id;
                        this.$toast.add({ severity:'success', summary: 'Guardado', detail: 'Usuario creado correctamente', life: 2000 });
                    }catch(e){
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                    }
                }else{
                    /* put  modulos/pwgsapi/index.php/usuarios/:id */
                    const subidadatos = { nombre: this.login, contraseña: this.clave , email: this.email, clave_mail_usuario: this.claveemail};
                    try {
                        await api.put('usuarios/' + this.operarioaux.idusuario, subidadatos);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
                    }
                }
                //this.cargardatos();
            
            
            this.$store.dispatch('cambiarGuardando', false);
        },
    },
    mounted(){
        this.cargardatos();
    },
    watch: {
        operario() {
            this.cargardatos();
        }
    },
}
</script>